import logo from './logo.svg';
import './App.css';
//import Header from "./app/components/layout/header/Header.tsx";
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap/dist/css/style.bundle.css';
// import { Footer } from './app/components/layout/footer/footer.tsx';
// import {AsideDefault} from './app/components/aside/AsideDefault.tsx';
// import { Toolbar } from './app/components/layout/toolbar/Toolbar.tsx';
// import { Content } from './app/components/layout/content/Content.tsx';
//import {Header} from './app/components/layout/header/header.tsx';
import { Home } from './components/Home';


function App() {
  return (
    // <div className="App">
    //   {/*<Topbar/>  */}
    //   <HeaderWrapper/>    
    //   <AsideDefault/>
    //   <Footer/>
      
    // </div>

   // <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        {/* <AsideDefault /> */}
        <div className='wrapper d-flex flex-column flex-row-fluid flex-lg-grow-1 ' id='kt_wrapper'>
          {/* <header /> */}
          <h1>Hey hey</h1>
          <Home/>

          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            {/* <Toolbar/> */}
             <div className='post d-flex flex-column-fluid' id='kt_post'>
              {/* <Content>{children}</Content> */}
              {/* <Content/> */}
            </div> 
          </div>
          {/* <Footer /> */}
        </div>
      </div>
//      </PageDataProvider>

  );
}

export default App;
